import React from 'react';

import { Icon } from 'semantic-ui-react';

const PORTAL_URL = process.env.REACT_APP_PORTAL_URL || 'https://portal-test-ph.offsidegaming.com';

export const PortalUserLink = ({
  id,
  children,
  icon,
}: {
  id: string;
  children?: React.ReactNode;
  icon?: boolean;
}) => (
  <a
    id="portal-user-link"
    target="_blank"
    rel="noopener noreferrer"
    href={`${PORTAL_URL}/offside-portal/home#!player-manager/${window.btoa(id)}/settings`}
  >
    {children} {icon && <Icon name="external" />}
  </a>
);
